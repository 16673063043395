import React, { Component } from "react";

import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import { blueGrey, grey } from '@mui/material/colors';

class NavBar extends Component {
  render() {
    const { sections } = this.props;
    return (
      <Toolbar
        component="nav"
        variant="dense"
        sx={{ justifyContent: "flex-start", overflowX: "auto", background: blueGrey[50], color: grey[900] }}
      >
        {sections.map((section) => {
          return section.callback != null ? (
            <Link
              color="inherit"
              noWrap
              key={section.title}
              variant="body2"
              onClick={section.callback}
              sx={{ p: 1, flexShrink: 0 }}
            >
              {section.title?.toUpperCase()}
            </Link>
          ) : (
            <Link
              color="inherit"
              noWrap
              key={section.title}
              variant="body2"
              href={section.url}
              sx={{ p: 1, flexShrink: 0 }}
            >
              {section.title?.toUpperCase()}
            </Link>
          )
        })}
      </Toolbar>
    );
  }
}

NavBar.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      callback: PropTypes.func,
    })
  ).isRequired,
  isLoggedIn: PropTypes.bool,
};

export default NavBar;
