// import { StrictMode } from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { createBrowserHistory as history } from "history";

import store from "./redux/store";
import AdminLayout from "./layouts/AdminLayout";

const App = () => {
  return (
    <>
      <AdminLayout history={history} location={window.location} />
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
const defaultTheme = createTheme();

root.render(
  //<StrictMode>
  <ThemeProvider theme={defaultTheme}>
    <CssBaseline />
    <Provider store={store()}>
      <Router>
        <App />
      </Router>
    </Provider>
  </ThemeProvider>
  //</StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
