/* station code actions */
import axios from "axios";

export const GET_HEALTH_CHECK_API_START = 'GET_HEALTH_CHECK_API_START';
export const GET_HEALTH_CHECK_API_SUCCESS = 'GET_HEALTH_CHECK_API_SUCCESS';
export const GET_HEALTH_CHECK_API_ERROR = 'GET_HEALTH_CHECK_API_ERROR';
export const UPDATE_HEALTH_CHECK_API_START = 'UPDATE_HEALTH_CHECK_API_START';
export const UPDATE_HEALTH_CHECK_API_SUCCESS = 'UPDATE_HEALTH_CHECK_API_SUCCESS';
export const UPDATE_HEALTH_CHECK_API_ERROR = 'UPDATE_HEALTH_CHECK_API_ERROR';

export const getHealthCheckAPI = () => {
    return async dispatch => {
        dispatch({type: GET_HEALTH_CHECK_API_START});
        await axios.get('/api/getHealthCheckList')
        .then(res => {
            const { data } = res;
            return dispatch({
                type: GET_HEALTH_CHECK_API_SUCCESS,
                payload: data,
            });
        })
        .catch(error => {
            return dispatch({ 
                type: GET_HEALTH_CHECK_API_ERROR,
                error: error
            });
        });
    };
};

export const updateHealthCheckAPI = (apiKey = null) => {
    return async dispatch => {
        dispatch({type: UPDATE_HEALTH_CHECK_API_START});
        await axios.post('/api/updateHealthCheckList', {
            userID: 'admin',
            apiKey
        })
        .then(res => {
            const { data } = res;
            return dispatch({
                type: UPDATE_HEALTH_CHECK_API_SUCCESS,
                payload: data,
            });
        })
        .catch(error => {
            return dispatch({ 
                type: UPDATE_HEALTH_CHECK_API_ERROR,
                error: error
            });
        });
    };
};