/* kiosk status actions */
import axios from "axios";

export const GET_KIOSKINFO_START = 'GET_KIOSKINFO_START';
export const GET_KIOSKINFO_SUCCESS = 'GET_KIOSKINFO_SUCCESS';
export const GET_KIOSKINFO_ERROR = 'GET_KIOSKINFO_ERROR';

export const getKioskInfo = () => {
    return async dispatch => {
        dispatch({type: GET_KIOSKINFO_START});
        await axios.get('/api/kioskStatus')
        .then(res => {
            const { data } = res;
            return dispatch({
                type: GET_KIOSKINFO_SUCCESS,
                payload: {
                    kiosksData: data
                },
            });
        })
        .catch(error => {
            return dispatch({ 
                type: GET_KIOSKINFO_ERROR,
                error: error
            });
        });
    };
};