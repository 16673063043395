import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reloadWayfindingRoutes } from '../../redux/wayFinding/wayFinding.action';
import { wayFindingGetError, wayFindingGetLoading, wayFindingGetSynTime } from '../../redux/wayFinding/wayFinding.selector';

import './wayfinding.scss';

const Select = ({options, label, selected, onChange, name}) => {
    return (
      <div className="select-group">
        {label}
        <select id="select" onChange={onChange} value={selected} name={name}>
            {options.map(option => (
                <option key={option.value} value={option.value}>
                    {typeof option.label === 'string' ? option.label : option.value}
                </option>
            ))}
        </select>
      </div>
    );
};
  

const Wayfinding = (props) => {
    const dispatch = useDispatch();
    const state = useSelector(s => s.wayFinding);
    const isLoading = wayFindingGetLoading(state);
    const lastSyncTime = wayFindingGetSynTime(state);
    const error = wayFindingGetError(state);    

    const [selected, setSelected] = useState('v1');

    const getReloadButton = () => {
        return (
          <div>
            <button className='btn-default' onClick={() => { dispatch(reloadWayfindingRoutes()) }} disabled={isLoading}>
              {isLoading ? 'Reloading routes ...' : 'WayFinding Route Reload'}
            </button>
            {/* <button className='btn-default' onClick={this.onUpdateLocation} disabled={Boolean(this.state.saving || this.state.closureError)}>
              {this.state.saving ? 'saving...' : 'Update location'}
            </button> */}
            { error ?
              <p className='error'>Error: {error}</p> : ''
            }
            { lastSyncTime ?
              <p className='last-save'>reloaded at: {new Date(lastSyncTime).toString()}</p> : ''
            }
          </div>
        );
      }

    const handleVersionChange = (e) => {
        setSelected(e.target.value);
    }

    return (
        <div className='wayfindConfig'>
            <div className='wayfind-config__version'>
                <h1>WAYFINDING VERSION</h1>
                <Select
                    options={['v1'].map(value => { return {value}; })}
                    selected={selected}
                    onChange={handleVersionChange}
                />
                {getReloadButton()}
            </div>
            {/* <div className='wayfind-config__closure'>
                <h4>WAYFINDING CLOSURES</h4>
                <div className='closure'>
                    <div className='closure__form'>
                        <h5>OPTIONS: </h5>
                        <div className='closure__options control-group'>
                            {this.getClosureOptions()}
                        </div>
                    </div>
                    <div className='closure__preview'>
                        <h5>PREVIEW: </h5>
                        { this.state.closureError ?
                            <div className='closure__error'>
                            <p className='error'>{this.state.closureError}</p>
                            </div> : ''
                        }
                        <Select options={this.getAreaToOptions()} onChange={this.handleAreaChange} name='area' />
                        <Select options={this.getRouteFromOptions()} onChange={this.handleRouteChange} name='source' />
                        <Select options={this.getRouteToOptions()} onChange={this.handleRouteChange} name='target' />
                        <button className='btn-default' onClick={this.handleBarrierFreeClick}>
                            {this.props.showBarrierFree ? 'show regular route' : 'show barrier free route'}
                        </button>
                        <div className={'closure__map'}>
                            <MobileMap />
                        </div>
                    </div>
                </div>
                <div className='closure__button'>
                    {this.getSaveButton()}
                </div>
            </div> */}
        </div>
    );
};

export default Wayfinding;