import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { getKioskInfo } from '../../redux/kioskInfo/kioskInfo.action';
import { kioskInfoGetData, kioskInfoGetLoading } from '../../redux/kioskInfo/kioskInfo.selector';
import { getKioskConfig } from '../../redux/kioskConfig/kioskConfig.action';
import { kioskConfigGetGlobalConfig } from '../../redux/kioskConfig/kioskConfig.selector';
import { healthCheckGetList, healthCheckGetLoading } from '../../redux/healthCheckList/healthCheckList.selector';
import './monitor.scss'
import { getHealthCheckAPI, updateHealthCheckAPI } from '../../redux/healthCheckList/healthCheckList.action';

const Monitor = (props) => {
    const dispatch = useDispatch();
    const state = useSelector(s => s);
    const kioskInfoData = kioskInfoGetData(state.kioskInfo);
    const globalConfig = kioskConfigGetGlobalConfig(state.kioskConfig);
    const healthCheckApi = healthCheckGetList(state.healthCheckList);
    const healthCheckApiLoading = healthCheckGetLoading(state.healthCheckList);
    const isLoading = kioskInfoGetLoading(state.kioskInfo);
    const [refreshTime, setRefreshTime] = useState(new Date().toLocaleString());

    useEffect(() => {
        dispatch(getKioskInfo());
        dispatch(getKioskConfig());
        dispatch(getHealthCheckAPI());

        const statusUpdate = setInterval(() => {
            dispatch(getKioskInfo());
            setRefreshTime(new Date().toLocaleTimeString());
        }, 1000 * 60 * 1);

        return () => {
            clearInterval(statusUpdate);
        };
    }, [])

    const renderMonitorInfo = () => {
        return kioskInfoData?.map((kiosk) => {
            return (
                <tr key={kiosk.id}>
                    <td>{kiosk.username}</td>
                    <td>{kiosk.status?.online ? 'Online' : 'Disconnected'}</td>
                    <td>{kiosk.status?.online ? (kiosk.status?.idle ? 'IDLE' : 'ACTIVE') : '-'}</td>
                    <td>{kiosk.status?.lastLoginTime}</td>
                    <td>{kiosk.status?.emailSent ? 'Sent' : 'Not send yet / Off'}</td>
                </tr>
            );
        });
    };

    const renderAPIList = () => {
        return healthCheckApi?.map((api, i) => {
            return (
                <tr id={i} index={i}>
                    <td >{api.apiName}</td>
                    <td>{api.status ? 'OK' : 'FAIL'}</td>
                    <td>{api.lastUpdatedTime}</td>
                    <td>
                        <button type="button" onClick={() => handleHealthCheckClick(api.apiName)}>
                            Refresh
                        </button>    
                    </td>
                    <td>
                        <button type="button" onClick={() => handleExportErrorLogFile(api.apiName)} style={{width: '100%'}}>
                            Export
                        </button>    
                    </td>
                    <td>
                        <a target={'_blank'} rel={'noreferrer'} href={api.url}>{api.url}</a>
                    </td>
                </tr>
            );
        });
    };

    const handleHealthCheckClick = (apiKey = null) => {
        dispatch(updateHealthCheckAPI(apiKey));
    };

    const handleExportErrorLogFile = async (apiKey) => {
        const data = await axios.get(`/api/healthCheckErrorLog?apiKey=${apiKey}`);
        console.log(data)

        const blob = new Blob([data?.data.join('\n')], { type: "text/plain", endings: 'native' });
        const link = document.createElement("a");

        link.href = URL.createObjectURL(blob);
        link.download = `${apiKey}_${moment().format('YYYYMMDDHHmmss')}.txt`;
        link.click();
    };

    return (
        <>
        {
            isLoading ? 
            (
                <h1>...Loading Kiosk login</h1>
            ) : 
            (
                <div className='infoContainer'>
                    <h1>KIOSK MONITOR - Refreshed at {refreshTime}</h1>
                    <h1>Time of Death : { globalConfig ? globalConfig.kioskTimeoutMins : '...'} mins</h1>
                    <table className="configureTable">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Status</th>
                                <th>Idle</th>
                                <th>Last Update Time</th>
                                <th>Offline Email Sent</th>
                            </tr>
                        </thead>
                        <tbody>
                            { renderMonitorInfo() }
                        </tbody>
                    </table>
                </div>
            )
        }
        {
            healthCheckApiLoading ? 
            (
                <h1>...Loading Health Check API</h1>
            ) : 
            (
                <div className='infoContainer'>
                    <div style={{display: 'inline'}}>
                        <h1>Health Check API List</h1>
                        <button className="btn-default" onClick={() => handleHealthCheckClick()}
                            disabled={healthCheckApiLoading}>
                            { 'Refresh' }
                        </button>
                    </div>
                    <table className="configureTable">
                        <thead>
                            <tr>
                                <th>API</th>
                                <th>Status</th>
                                <th>Last Updated Time</th>
                                <th>Refresh</th>
                                <th>Export Error Log</th>
                                <th>API Url</th>
                            </tr>
                        </thead>
                        <tbody>
                            { renderAPIList() }
                        </tbody>
                    </table>
                </div>
            )            
        }
        </>
    );
};

export default Monitor;