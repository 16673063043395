/* kiosk status Reducer */
import { fromJS } from 'immutable';

import {
    GET_KIOSKCONFIG_START,
    GET_KIOSKCONFIG_SUCCESS,
    GET_KIOSKCONFIG_ERROR,
    UPDATE_KIOSKCONFIG_START,
    UPDATE_KIOSKCONFIG_GLOBAL_SUCCESS,
    UPDATE_KIOSKCONFIG_INDIV_SUCCESS,
    UPDATE_KIOSKCONFIG_ERROR,
} from './kioskConfig.action';

const initialState = fromJS({
    kioskGlobalConfig: {},
    kioskIndividualConfig: [],
    isLoading: false,
    error: '',
});

export const kioskConfig = (state = initialState, action) => {
    if (!action || !action.type) {
        return state;
    }
    switch (action.type) {
        case GET_KIOSKCONFIG_START:
            return state.withMutations(ctx => {
                ctx.set('kioskGlobalConfig', {});
                ctx.set('kioskIndividualConfig', []);
                ctx.set('isLoading', true);
                ctx.set('error', '');
            });
        case GET_KIOSKCONFIG_SUCCESS:
            return state.withMutations(ctx => {
                const { kioskGlobalConfig, kioskIndividualConfig } = action.payload;
                ctx.set('kioskGlobalConfig', kioskGlobalConfig);
                ctx.set('kioskIndividualConfig', kioskIndividualConfig);
                ctx.set('isLoading', false);
            });
        case GET_KIOSKCONFIG_ERROR:
            return state.withMutations(ctx => {
                ctx.set('kioskGlobalConfig', {});
                ctx.set('kioskIndividualConfig', []);
                ctx.set('isLoading', false);
                ctx.set('error', (action.error || 'you need an error message'));
            });
        case UPDATE_KIOSKCONFIG_START:
            return state.withMutations(ctx => {
                ctx.set('isLoading', true);
                ctx.set('error', '');
            });
        case UPDATE_KIOSKCONFIG_GLOBAL_SUCCESS:
            return state.withMutations(ctx => {
                const { data } = action.payload;
                let kioskGlobalConfig = ctx.get('kioskGlobalConfig');
                ctx.set('kioskGlobalConfig', {...kioskGlobalConfig, ...data});
                ctx.set('isLoading', false);
            });
        case UPDATE_KIOSKCONFIG_INDIV_SUCCESS:
            return state.withMutations(ctx => {
                const { data } = action.payload;
                let kiosksIndivConfig = ctx.get('kioskIndividualConfig');
                ctx.set('kioskIndividualConfig', kiosksIndivConfig.map((kiosk) => {
                    return kiosk._id === data._id ? {...kiosk, ...data} : kiosk;
                }));
                ctx.set('isLoading', false);
            });
        case UPDATE_KIOSKCONFIG_ERROR:
            return state.withMutations(ctx => {
                ctx.set('isLoading', false);
                ctx.set('error', (action.error || 'you need an error message'));
            });
        default:
            return state;
    };
};
