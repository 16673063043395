import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import immutableTransform from 'redux-persist-transform-immutable'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './state';

const middleware = [thunkMiddleware];

const store = () => {
    const isServer = typeof window === 'undefined';

    if (isServer) {
        return configureStore({
            reducer: rootReducer,
            middleware,
        });
    } else {
        const persistConfig = {
            transforms: [immutableTransform()],
            key: 'root',
            storage: AsyncStorage,
            whitelist: ['login']
        };

        const persistedReducer = persistReducer(persistConfig, rootReducer);

        const store = configureStore({
            reducer: persistedReducer,
            middleware,
        });
        store.__persistor = persistStore(store);

        return store;
    };
}

export default store;
