import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar, GridActionsCellItem, GridRowModes, GridRowEditStopReasons } from '@mui/x-data-grid';
import { Edit as EditIcon, Save as SaveIcon, Cancel as CancelIcon } from '@mui/icons-material'
import _ from 'lodash';

import './configure.scss';
import { getKioskConfig, updateKioskConfig } from '../../redux/kioskConfig/kioskConfig.action';
import { kioskConfigGetGlobalConfig, kioskConfigGetIndivConfig, kioskConfigGetLoading } from '../../redux/kioskConfig/kioskConfig.selector';

const ConfigTable = (props) => {
    const { 
        boxWidth,
        columns,
        rows,
        rowModesModel,
        type,
        setRowModesModel,
        setGlobalConfigErrorMessage
    } = props;

    const dispatch = useDispatch();

    const ationColumn = {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        type: 'actions',
        cellClassName: 'actions',
        getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

            if (isInEditMode){
                return [
                    <GridActionsCellItem
                    icon={<SaveIcon />}
                    label='Save'
                    sx={{
                      color: 'primary.main',
                    }}
                    onClick={handleSaveClick(id)}
                  />,
                  <GridActionsCellItem
                    icon={<CancelIcon />}
                    label='Cancel'
                    className='textPrimary'
                    onClick={handleCancelClick(id)}
                    color='inherit'
                  />,
                ]
            };

            return [
                <GridActionsCellItem
                icon={<EditIcon />}
                label='Edit'
                className='textPrimary'
                onClick={handleEditClick(id)}
                color='inherit'
              />,
            ];
        },
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };
    
    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };
    
    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
          event.defaultMuiPrevented = true;
        }
    };

    const handleRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        const { 
            //  Indiv handling
            deviceNameEN,
            deviceNameHK,
            deviceNameCN,
            //  Detect handling
            detectAreaX1,
            detectAreaY1,
            detectAreaX2,
            detectAreaY2,
            detectedPercentage,
            detectTime,
            //  ---------
            active,
            ...indiDatas 
        } = _.omit(updatedRow, 'id');

        //  Check incorrect data input
        if ((newRow?.setting === 'kiosksOpenHour' || newRow?.setting === 'kiosksCloseHour') && newRow?.value) {
            let newTime = newRow.value;
            //  Check is it in 4 digits
            if (newTime.length !== 4) {
                setGlobalConfigErrorMessage('Open/Close Hour are not in 4 digits');
                return;
            }

            //  Check is it a valid time
            if (!/^\d+$/.test(newTime) ||  parseInt(newTime) < 0 || parseInt(newTime) > 2359) {
                setGlobalConfigErrorMessage('Open/Close Hour should between 0000 and 2359');
                return;                
            }
        }

        //  Handle updatedData 
        const updatedData = 
            type === 'global' ? {
                _id: 'SharedSetting',
                [newRow.setting]: newRow.value,
            } : 
            type === 'indiv' ? {
                ...indiDatas,
                deviceName: {
                    en: deviceNameEN,
                    hk: deviceNameHK,
                    cn: deviceNameCN,
                },
                active
            } : {
                ...indiDatas,
                personDetection: {
                    active: active,
                    detectArea: {
                        x1: detectAreaX1,
                        y1: detectAreaY1,
                        x2: detectAreaX2,
                        y2: detectAreaY2,
                    },
                    detectedPercentage: detectedPercentage,
                    detectTime: detectTime
                }
            }
        
        dispatch(updateKioskConfig(updatedData));
        setGlobalConfigErrorMessage('');
        return updatedRow;
    }
    
    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleRowUpdateError = (error) => () => {
        console.log(error)
    }


    return (
        <Box sx={{ width: boxWidth }}>
            <DataGrid
                sx={{ m: 2 }}
                className='configureTable'
                rows={rows} 
                columns={[...columns, ationColumn]}
                hideFooter={true}
                autoHeight
                disableSelectionOnClick
                editMode={'row'}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={(newRow) => handleRowUpdate(newRow)}
                onProcessRowUpdateError={(error) => handleRowUpdateError(error)}
                slots={{
                    toolbar: GridToolbar,
                }}
            />
        </Box>
    );
};

const Configure = (props) => {
    const { buildingId } = props;

    const dispatch = useDispatch();
    const state = useSelector(s => s.kioskConfig);
    const globalConfig = kioskConfigGetGlobalConfig(state);
    const indivConfig = kioskConfigGetIndivConfig(state);
    const isLoading = kioskConfigGetLoading(state);

    const [rowModesGlobalModel, setRowModesGlobalModel] = useState({});
    const [rowModesIndivModel, setRowModesIndivModel] = useState({});
    const [rowModesPersonDetectModel, setRowModesPersonDetectModel] = useState({});
    const [globalConfigErrorMessage, setGlobalConfigErrorMessage] = useState('');

    useEffect(() => {
        dispatch(getKioskConfig());
    }, [])


    //  Define Config Table Columns
    //  GLOBAL CONFIG
    const globalConfigCols = [
        { field: 'setting', headerName: 'Setting', editable: false, flex: 3 },
        { field: 'value', headerName: 'Value', editable: true, flex: 3 },
    ];

    const globalConfigRows = Object.keys(_.omit(globalConfig, '_id')).map((key, i) => {
        return {
            id: i,
            setting: key,
            value: typeof globalConfig[key] === 'object' ? JSON.stringify(globalConfig[key]) : globalConfig[key] 
        };
    });

    //  INDIVIDUAL CONFIG
    const indivConfigCols = [
        //  Non-Editable
        { field: 'deviceId', headerName: 'DeviceId', editable: false , flex: 1 },
        { field: 'wfNodeId', headerName: 'WfNodeId', editable: false, flex: 1  },
        //  Selection Items
        { field: 'youRotation', headerName: 'YouRotation', editable: true, flex: 1, type: 'singleSelect', valueOptions: [ 0, 45, 90, 135, 180, 225, 270, 315 ] },
        // { field: 'orientation', headerName: 'Orientation', editable: true, flex: 1, type: 'singleSelect', valueOptions: [ 'portrait', 'landscape', '' ] },
        // { field: 'micPosition', headerName: 'MicPosition', editable: true, flex: 1, type: 'singleSelect', valueOptions: [ 'R', 'L', '' ] },
        // { field: 'idleScreen', headerName: 'IdleScreen', editable: true, flex: 1, type: 'singleSelect', valueOptions: [ 'food', '' ] },
        { field: 'buildingId', headerName: 'BuildingId', editable: true, flex: 1.4, type: 'singleSelect', valueOptions: [ `mtr-${buildingId}`, '' ] },
        //  String Items
        { field: 'deviceNameEN', headerName: 'DeviceName(EN)', editable: true, flex: 1.5 },
        { field: 'deviceNameHK', headerName: 'DeviceName(HK)', editable: true, flex: 1.5 },
        { field: 'deviceNameCN', headerName: 'DeviceName(CN)', editable: true, flex: 1.5 },
        { field: 'callForHelpTag', headerName: 'CallForHelpTag', editable: true, flex: 1.5 },
        { field: 'notes', headerName: 'Notes', editable: true, flex: 1 },
        //  True/False Items
        { field: 'camera', headerName: 'Camera', editable: true, flex: 0.5, type: 'boolean' },
        { field: 'email', headerName: 'Email', editable: true, flex: 0.5, type: 'boolean' },
        { field: 'mic', headerName: 'Mic', editable: true, flex: 0.6, type: 'boolean' },
        { field: 'active', headerName: 'Activate', editable: true, flex: 0.6, type: 'boolean' },
    ];

    const indivConfigRows = indivConfig.map((kiosk, i) => {
        const { deviceName, personDetection, ...restkiosk } = kiosk;
        return {
            id: kiosk._id,
            ...restkiosk,
            deviceNameEN: deviceName['en'],
            deviceNameHK: deviceName['hk'],
            deviceNameCN: deviceName['cn'],
        }
    });

    //  KIOSK CAMERA PERSON DETECTION CONFIG
    const personDetectConfigCols = [
        //  Non-Editable
        { field: 'deviceId', headerName: 'DeviceId', editable: false , flex: 1 },
        //  Number Items
        { field: 'detectAreaX1', headerName: 'DetectArea_X1', editable: true, flex: 1, type: 'number' },
        { field: 'detectAreaY1', headerName: 'DetectArea_Y1', editable: true, flex: 1, type: 'number' },
        { field: 'detectAreaX2', headerName: 'DetectArea_X2', editable: true, flex: 1, type: 'number' },
        { field: 'detectAreaY2', headerName: 'DetectArea_Y2', editable: true, flex: 1, type: 'number' },
        { field: 'detectedPercentage', headerName: 'Detected%', editable: true, flex: 1, type: 'number' },
        { field: 'detectTime', headerName: 'DetectTime', editable: true, flex: 1, type: 'number' },
        //  True/False Items
        { field: 'active', headerName: 'Activate', editable: true, flex: 1, type: 'boolean' },
    ];

    const personDetectConfigRows = indivConfig.map((kiosk, i) => {
        const { personDetection } = kiosk;
        return {
            id: kiosk._id,
            _id: kiosk._id,
            deviceId: kiosk._id,
            detectAreaX1: personDetection.detectArea.x1,
            detectAreaY1: personDetection.detectArea.y1,
            detectAreaX2: personDetection.detectArea.x2,
            detectAreaY2: personDetection.detectArea.y2,
            detectedPercentage: personDetection.detectedPercentage,
            detectTime: personDetection.detectTime,
            active: personDetection.active,
        }
    });
    // `END Define Config Table Columns

    //  Config Table Editing Handling
    const [globalRows, setGlobalRows] = useState([]);
    const [indivRows, setIndivRows] = useState([]);

    useEffect(()=> {
        if (globalConfigRows.length){
            setGlobalRows(globalConfigRows);
        }
    },[globalConfig]);
    
    useEffect(()=> {
        if (indivConfigRows.length){
            setIndivRows(indivConfigRows);
        }
    },[indivConfig]);
    //  END Config Table Editing Handling

    return (
        <>
        {
            isLoading ? 
            (
                <h1>...Loading</h1>
            ) : 
            (
                <div className='configContainer'>
                    <h1>GLOBAL KIOSK CONFIGURATION</h1>
                    {
                        _.isEmpty(globalConfig) ? 
                        <h2>NO GLOBAL CONFIG DATA</h2> : 
                        <div>
                            <ConfigTable 
                                boxWidth='50%'
                                columns={globalConfigCols}
                                rows={globalRows}
                                editMode='row'
                                rowModesModel={rowModesGlobalModel}
                                type={'global'}
                                setGlobalConfigErrorMessage={setGlobalConfigErrorMessage}
                                setRowModesModel={setRowModesGlobalModel}
                            />
                            <p>
                                **Open Hour and Close Hour will only take last 4 digits
                                <br/>
                                **Adding "-" at the beginning of emailList will disable email notification
                                <br/>
                                {globalConfigErrorMessage}
                            </p>
                        </div>
                    }
                    <h1>INDIVIDUAL KIOSK CONFIGURATION</h1>
                    {                        
                        indivConfig.length ? 
                        <ConfigTable
                            boxWidth='100%' 
                            columns={indivConfigCols} 
                            rows={indivRows} 
                            editMode='row'
                            rowModesModel={rowModesIndivModel}
                            type={'indiv'}
                            setRowModesModel={setRowModesIndivModel}
                        /> : 
                        <h2>NO INDIVIDUAL CONFIG DATA</h2>
                    }
                    <h1>KIOSK PERSON DETECCTION CONFIGURATION</h1>
                    {                        
                        indivConfig.length ? 
                        <ConfigTable
                            boxWidth='100%' 
                            columns={personDetectConfigCols} 
                            rows={personDetectConfigRows} 
                            editMode='row'
                            rowModesModel={rowModesPersonDetectModel}
                            type={'personDetect'}
                            setRowModesModel={setRowModesPersonDetectModel}
                        /> : 
                        <h2>NO KIOSK PERSON DETECCTION CONFIG DATA</h2>
                    }
                </div>
            )
        }
        </>
    );
};

export default Configure;