/* kiosk status Reducer */
import { fromJS } from 'immutable';

import {
    SYNC_CMS_START,
    SYNC_CMS_SUCCESS,
    SYNC_CMS_ERROR,
    SYNC_ALL_START,
    SYNC_ALL_SUCCESS,
    SYNC_ALL_ERROR,
} from './syncDB.action';

const initialState = fromJS({
    isLoading: false,
    cmsSyncText: 'CMS Content Sync',
    fullSyncText: 'Force Full Sync',
    lastSynTime: '',
    error: '',
});

export const syncDB = (state = initialState, action) => {
    if (!action || !action.type) {
        return state;
    }
    switch (action.type) {
        case SYNC_CMS_START:
        case SYNC_ALL_START:
            return state.withMutations(ctx => {
                const { contentSyncOnly } = action.payload;
                ctx.set(contentSyncOnly ? 'cmsSyncText' : 'fullSyncText', 'Syncing...');
                ctx.set('lastSynTime', '');
                ctx.set('isLoading', true);
                ctx.set('error', '');
            });
        case SYNC_CMS_SUCCESS:
        case SYNC_ALL_SUCCESS:
            return state.withMutations(ctx => {
                ctx.set('cmsSyncText', 'CMS Content Sync');
                ctx.set('fullSyncText', 'Force Full Sync');
                ctx.set('lastSynTime', Date.now());
                ctx.set('isLoading', false);
            });
        case SYNC_CMS_ERROR:
        case SYNC_ALL_ERROR:
            return state.withMutations(ctx => {
                ctx.set('cmsSyncText', 'CMS Content Sync');
                ctx.set('fullSyncText', 'Force Full Sync');
                ctx.set('isLoading', false);
                ctx.set('error', (action.error || 'you need an error message'));
            });
        default:
            return state;
    };
};
