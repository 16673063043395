import { useDispatch, useSelector } from 'react-redux';
import { syncDBGetCMSSyncText, syncDBGetError, syncDBGetFullSyncText, syncDBGetLoading, syncDBGetSynTime } from '../../redux/syncDB/syncDB.selector';
import { syncCMSContent, syncAll } from '../../redux/syncDB/syncDB.action';

import './sync.scss';

const Sync = (props) => {
    const dispatch = useDispatch();
    const state = useSelector(s => s.syncDB);
    const cmsSyncText = syncDBGetCMSSyncText(state);
    const fullSyncText = syncDBGetFullSyncText(state);
    const isLoading = syncDBGetLoading(state);
    const lastSyncTime = syncDBGetSynTime(state);
    const error = syncDBGetError(state);    
    
    const handleCmsContentSync = () => {
        dispatch(syncCMSContent());
    };

    const handleFullSync = () => {
        dispatch(syncAll());
    };


    return (
        <div className="sync">
            <div className="sync__all">
                <section>
                    <h4>SMART SYNC FROM API</h4>
                    <button className="btn-default" onClick={handleCmsContentSync}
                        disabled={isLoading}>
                        { cmsSyncText }
                    </button>
                </section>
                <section>
                    <h4>FORCE FULL SYNC FROM API</h4>
                    <button className="btn-default btn-danger" onClick={handleFullSync}
                        disabled={isLoading}>
                        { fullSyncText }
                    </button>
                </section>
                <section>
                { 
                    error ? 
                    <p className="error">
                        Error: { error }
                    </p> : '' 
                }
                {
                    lastSyncTime ?
                    <p className="lastSync">
                        Synced at: { new Date(lastSyncTime).toString() }
                    </p> : ''
                }
                </section>
            </div>
        </div>
    );
};

export default Sync;