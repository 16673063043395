/* station code Reducer */
import { fromJS } from 'immutable';

import {
    GET_HEALTH_CHECK_API_START,
    GET_HEALTH_CHECK_API_SUCCESS,
    GET_HEALTH_CHECK_API_ERROR,
    UPDATE_HEALTH_CHECK_API_START,
    UPDATE_HEALTH_CHECK_API_SUCCESS,
    UPDATE_HEALTH_CHECK_API_ERROR
} from './healthCheckList.action';

const initialState = fromJS({
    apiList: [],
    isLoading: false,
    error: '',
});

export const healthCheckList = (state = initialState, action) => {
    if (!action || !action.type) {
        return state;
    }
    switch (action.type) {
        case GET_HEALTH_CHECK_API_START:
        case UPDATE_HEALTH_CHECK_API_START:
            return state.withMutations(ctx => {
                ctx.set('apiList', []);
                ctx.set('isLoading', true);
                ctx.set('error', '');
            });
        // case UPDATE_HEALTH_CHECK_API_START:
        //     return state.withMutations(ctx => {
        //         ctx.set('isLoading', true);
        //         ctx.set('error', '');
        //     });
        case GET_HEALTH_CHECK_API_SUCCESS:
        case UPDATE_HEALTH_CHECK_API_SUCCESS:
            return state.withMutations(ctx => {
                const { data } = action.payload;
                ctx.set('apiList', data);
                ctx.set('isLoading', false);
            });
        // case UPDATE_HEALTH_CHECK_API_SUCCESS:
        //     return state.withMutations(ctx => {
        //         ctx.set('isLoading', false);
        //     });
        case GET_HEALTH_CHECK_API_ERROR:
        case UPDATE_HEALTH_CHECK_API_ERROR:
            return state.withMutations(ctx => {
                ctx.set('apiList', '');
                ctx.set('isLoading', false);
                ctx.set('error', (action.error || 'you need an error message'));
            });
        default:
            return state;
    };
};
