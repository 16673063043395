/* reload wayfinding route actions */
import axios from "axios";

export const RELOAD_WAYFINDER_START = 'RELOAD_WAYFINDER_START';
export const RELOAD_WAYFINDER_SUCCESS = 'RELOAD_WAYFINDER_SUCCESS';
export const RELOAD_WAYFINDER_ERROR = 'RELOAD_WAYFINDER_ERROR';

export const reloadWayfindingRoutes = () => {
    return async dispatch => {
        dispatch({ type: RELOAD_WAYFINDER_START });
        await axios.post(`/api/wayfinder/reload`, {
            userId: 'admin'
        })
        .then(res => {
            return dispatch({ type: RELOAD_WAYFINDER_SUCCESS });
        })
        .catch(error => {
            return dispatch({ 
                type: RELOAD_WAYFINDER_ERROR,
                error: error
            });
        });
    };
};