import { login } from './login/login.reducer';
import { kioskInfo } from './kioskInfo/kioskInfo.reducer';
import { kioskConfig } from './kioskConfig/kioskConfig.reducer';
import { syncDB } from './syncDB/syncDB.reducer';
import { wayFinding } from './wayFinding/wayFinding.reducer';
import { stationCode } from './stationCode/stationCode.reducer';
import { healthCheckList } from './healthCheckList/healthCheckList.reducer';
import { combineReducers } from '@reduxjs/toolkit';

export const rootReducer = combineReducers({
    login,
    kioskInfo,
    kioskConfig,
    syncDB,
    wayFinding,
    stationCode,
    healthCheckList
});
