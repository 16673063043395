/* login/logout Reducer */
import { fromJS } from 'immutable';

import {
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT_SUCCESS
} from './login.action';

const initialState = fromJS({
    token: '',
    expiredTime: 0,
    error: '',
});

export const login = (state = initialState, action) => {
    if (!action || !action.type) {
        return state;
    }
    switch (action.type) {
        case LOGIN_SUCCESS:
            return state.withMutations(ctx => {
                const { token, expiredTime } = action.payload;
                const newDate = new Date();
                ctx.set('token', token);
                ctx.set('expiredTime', newDate.getTime() + expiredTime);
                ctx.set('error', '');
            });
        case LOGIN_ERROR:
            return state.withMutations(ctx => {
                ctx.set('token', '');
                ctx.set('expiredTime', 0);
                ctx.set('error', (action.error || 'you need an error message'));
            });
        case LOGOUT_SUCCESS:
            return state.withMutations(ctx => {
                ctx.set('token', '');
                ctx.set('expiredTime', 0);
                ctx.set('error', '');
            });
        default:
            return state;
    };
};
