/* kiosk status Reducer */
import { fromJS } from 'immutable';

import {
    GET_KIOSKINFO_START,
    GET_KIOSKINFO_SUCCESS,
    GET_KIOSKINFO_ERROR
} from './kioskInfo.action';

const initialState = fromJS({
    kiosksData: [],
    isLoading: false,
    error: '',
});

export const kioskInfo = (state = initialState, action) => {
    if (!action || !action.type) {
        return state;
    }
    switch (action.type) {
        case GET_KIOSKINFO_START:
            return state.withMutations(ctx => {
                ctx.set('kiosksData', []);
                ctx.set('isLoading', true);
                ctx.set('error', '');
            });
        case GET_KIOSKINFO_SUCCESS:
            return state.withMutations(ctx => {
                const { kiosksData } = action.payload;
                ctx.set('kiosksData', kiosksData);
                ctx.set('isLoading', false);
            });
        case GET_KIOSKINFO_ERROR:
            return state.withMutations(ctx => {
                ctx.set('isLoading', false);
                ctx.set('error', (action.error || 'you need an error message'));
            });
        default:
            return state;
    };
};
