/* reload wayfinding route Reducer */
import { fromJS } from 'immutable';

import {
    RELOAD_WAYFINDER_START,
    RELOAD_WAYFINDER_SUCCESS,
    RELOAD_WAYFINDER_ERROR,
} from './wayFinding.action';

const initialState = fromJS({
    isLoading: false,
    lastReloadTime: '',
    error: '',
});

export const wayFinding = (state = initialState, action) => {
    if (!action || !action.type) {
        return state;
    }
    switch (action.type) {
        case RELOAD_WAYFINDER_START:
            return state.withMutations(ctx => {
                ctx.set('lastReloadTime', '');
                ctx.set('isLoading', true);
                ctx.set('error', '');
            });
        case RELOAD_WAYFINDER_SUCCESS:
            return state.withMutations(ctx => {
                ctx.set('lastReloadTime', Date.now());
                ctx.set('isLoading', false);
            });
        case RELOAD_WAYFINDER_ERROR:
            return state.withMutations(ctx => {
                ctx.set('isLoading', false);
                ctx.set('error', (action.error || 'you need an error message'));
            });
        default:
            return state;
    };
};
