/* kiosk status actions */
import axios from "axios";

export const SYNC_CMS_START = 'SYNC_CMS_START';
export const SYNC_CMS_SUCCESS = 'SYNC_CMS_SUCCESS';
export const SYNC_CMS_ERROR = 'SYNC_CMS_ERROR';
export const SYNC_ALL_START = 'SYNC_ALL_START';
export const SYNC_ALL_SUCCESS = 'SYNC_ALL_SUCCESS';
export const SYNC_ALL_ERROR = 'SYNC_ALL_ERROR';

// const api = axios.create({
//     baseURL: 'https://mtr-front-server-test.fitch-hk.com/',
//     timeout: 300000,
//     headers: {
//         'Content-Type': 'application/json',
//         Accept: 'application/json',
//     },
// });

export const syncCMSContent = () => {
    return async dispatch => {
        dispatch({
            type: SYNC_CMS_START,
            payload: {
                contentSyncOnly: true
            },
        });
        await axios.post(`/api/syncCms`, {
            userID: 'admin'
        })
        .then(res => {
            console.log(res)
            return dispatch({ type: SYNC_CMS_SUCCESS });
        })
        .catch(error => {
            console.log(JSON.stringify(error))
            return dispatch({ 
                type: SYNC_CMS_ERROR,
                error: error
            });
        });
    };
};

export const syncAll = () => {
    return async dispatch => {
        dispatch({
            type: SYNC_ALL_START,
            payload: {
                contentSyncOnly: false
            },
        });
        await axios.post('/api/syncAll', {
            userID: 'admin'
        })
        .then(res => {
            return dispatch({ type: SYNC_ALL_SUCCESS });
        })
        .catch(error => {
            return dispatch({ 
                type: SYNC_ALL_ERROR,
                error: error
            });
        });
    };
};