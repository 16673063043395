import React, { Component } from "react";
import PropTypes from "prop-types";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { blueGrey, grey } from '@mui/material/colors';

import "./header.module.scss";
import NavBar from "../NavBar";

class Header extends Component {
  render() {
    const { sections, title, isLoggedIn } = this.props;
    return (
      <AppBar position="relative">
        <Toolbar sx={{ background: blueGrey[100], color: grey[900] }}>
          <Typography
            component="h2"
            variant="h5"
            color="inherit"
            align="center"
            noWrap
            sx={{ flex: 1 }}
          >
            {title}
          </Typography>
        </Toolbar>
        {
          isLoggedIn && <NavBar sections={sections} />
        }
      </AppBar>
    );
  }
}

Header.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  isLoggedIn: PropTypes.bool,
  title: PropTypes.string,
};

export default Header;
