/* station code Reducer */
import { fromJS } from 'immutable';

import {
    GET_STATION_START,
    GET_STATION_SUCCESS,
    GET_STATION_ERROR,
} from './stationCode.action';

const initialState = fromJS({
    station: '',
    isLoading: false,
    error: '',
});

export const stationCode = (state = initialState, action) => {
    if (!action || !action.type) {
        return state;
    }
    switch (action.type) {
        case GET_STATION_START:
            return state.withMutations(ctx => {
                ctx.set('station', '');
                ctx.set('isLoading', true);
                ctx.set('error', '');
            });
        case GET_STATION_SUCCESS:
            return state.withMutations(ctx => {
                const { data } = action.payload;
                ctx.set('station', data);
                ctx.set('isLoading', false);
            });
        case GET_STATION_ERROR:
            return state.withMutations(ctx => {
                ctx.set('station', '');
                ctx.set('isLoading', false);
                ctx.set('error', (action.error || 'you need an error message'));
            });
        default:
            return state;
    };
};
