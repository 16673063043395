import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Routes,
  Navigate,
  useSearchParams,
  useNavigate,
} from "react-router-dom";

import Configure from "../../pages/Configure";
import Login from "../../pages/Login";
import Monitor from "../../pages/Monitor";
import Sync from "../../pages/Sync";
import Wayfinding from "../../pages/Wayfinding";
import { loginAdminPannelWithToken, LogoutAdminPannel } from "../../redux/login/login.action";
import { loginGetDataTime } from "../../redux/login/login.selector";
import { getStationCode } from "../../redux/stationCode/stationCode.action";
import { stationCodeGetStation } from "../../redux/stationCode/stationCode.selector";

import "./AdminLayout.scss";
import Header from "../../components/Header";


const RequireAuth = (props) => {
  const state = useSelector((s) => s.login);
  const expiredTime = loginGetDataTime(state);

  const { children } = props;
  const date = new Date();
  return date.getTime() < expiredTime ? (
    children
  ) : (
    <Navigate to="/admin/login" />
  );
};

const AdminLayout = (props) => {
    const state = useSelector((s) => s);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [queryParameters] = useSearchParams();
    const userID = queryParameters.get("uid");
    const token = queryParameters.get("token");
    const station = stationCodeGetStation(state.stationCode);

    useEffect(() => {
        dispatch(getStationCode())
        if (userID && token) {
            dispatch(
                loginAdminPannelWithToken({
                userID,
                token,
                })
            );
            return navigate("/admin/monitor");
        }
    }, [])


    const expiredTime = loginGetDataTime(state.login);
    const [isLoggedIn, setIsLoggedIn] = useState(() => {
        const date = new Date();
        return date.getTime() < expiredTime;
    });

    const handleClickLogout = () => {
        dispatch(LogoutAdminPannel());
        navigate("/admin/login");
    }

    const sections = [
        { title: 'monitor', url: '/admin/monitor' },
        { title: 'configure', url: '/admin/configure' },
        { title: 'wayfinding', url: '/admin/wayfinding' },
        // { title: 'search', url: '/admin/search' },
        { title: 'sync', url: '/admin/sync' },
        // { title: 'baidu', url: '/admin/baidu' },
        { title: 'logout', url: '/admin/login', callback: handleClickLogout },
    ];

    //  Update login status
    useEffect(() => {
        const date = new Date();
        setIsLoggedIn(date.getTime() < expiredTime);
    }, [expiredTime]);

    return (
        <>
            <Header title={`MTR ${station}`} isLoggedIn={isLoggedIn} sections={sections} />
            <main className="flex-shrink-0">
                <div className="container">
                    <Routes>
                        <Route path="/admin/login" element={<Login {...props} />} />
                        <Route
                            path="/admin/monitor"
                            element={
                                <RequireAuth>
                                <Monitor />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/admin/configure"
                            element={
                                <RequireAuth>
                                <Configure buildingId={station?.toLowerCase()}/>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/admin/wayfinding"
                            element={
                                <RequireAuth>
                                <Wayfinding />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/admin/sync"
                            element={
                                <RequireAuth>
                                <Sync />
                                </RequireAuth>
                            }
                        />
                        <Route path="*" element={<Navigate to="/admin/login" />} />
                    </Routes>
                </div>
            </main>
        </>
    );
};

export default AdminLayout;
