/* kiosk config actions */
import axios from "axios";

export const GET_KIOSKCONFIG_START = 'GET_KIOSKCONFIG_START';
export const GET_KIOSKCONFIG_SUCCESS = 'GET_KIOSKCONFIG_SUCCESS';
export const GET_KIOSKCONFIG_ERROR = 'GET_KIOSKCONFIG_ERROR';
export const UPDATE_KIOSKCONFIG_START = 'UPDATE_KIOSKCONFIG_START';
export const UPDATE_KIOSKCONFIG_GLOBAL_SUCCESS = 'UPDATE_KIOSKCONFIG_GLOBAL_SUCCESS';
export const UPDATE_KIOSKCONFIG_INDIV_SUCCESS = 'UPDATE_KIOSKCONFIG_INDIV_SUCCESS';
export const UPDATE_KIOSKCONFIG_ERROR = 'UPDATE_KIOSKCONFIG_ERROR';

export const getKioskConfig = () => {
    return async dispatch => {
        dispatch({type: GET_KIOSKCONFIG_START});
        await axios.get('/api/kioskConfig')
        .then(res => {
            const { data: { sharedConfig, kiosksConfig } } = res;

            //  Get Shared Settings
            const kioskGlobalConfig = sharedConfig || {};
            //  Get Individual Kiosk Setting
            const kioskIndividualConfig = kiosksConfig || [];

            return dispatch({
                type: GET_KIOSKCONFIG_SUCCESS,
                payload: {
                    kioskGlobalConfig,
                    kioskIndividualConfig
                },
            });
        })
        .catch(error => {
            return dispatch({ 
                type: GET_KIOSKCONFIG_ERROR,
                error: error
            });
        });
    };
};

export const updateKioskConfig = (data) => {
    return async dispatch => {
        dispatch({type: UPDATE_KIOSKCONFIG_START});

        await axios.post('/api/kioskConfig', {
            data
        })
        .then(res => {
            const { data: result } = res;
            if (result.id === 'SharedSetting'){
                return dispatch({
                    type: UPDATE_KIOSKCONFIG_GLOBAL_SUCCESS,
                    payload: {
                        data
                    },
                });                
            } else {
                return dispatch({
                    type: UPDATE_KIOSKCONFIG_INDIV_SUCCESS,
                    payload: {
                        data
                    },
                }); 
            };
        })
        .catch(error => {
            return dispatch({ 
                type: UPDATE_KIOSKCONFIG_ERROR,
                error: error
            });
        });
    };
};